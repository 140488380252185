<script setup lang="ts">
import LoginForm from "@/components/auth/LoginForm.vue";
import VerifyEmail from "@/components/auth/VerifyEmail.vue";

await useSeoAndGetContent(ROUTES.login);

definePageMeta({
  layout: "fullpage",
});

const shouldVerifyEmail = ref(false);
const email = ref("");

const showVerifyEmail = (userEmail: string) => {
  email.value = userEmail;
  shouldVerifyEmail.value = true;
};
</script>

<template>
  <div>
    <transition name="slide-fade" mode="out-in">
      <LoginForm v-if="!shouldVerifyEmail" @verify-email="(userEmail) => showVerifyEmail(userEmail)" />
      <VerifyEmail v-else :email="email" @show-login="shouldVerifyEmail = false" />
    </transition>
  </div>
</template>
